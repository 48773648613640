// Variables
$small-screen: "screen and (max-width: 600px)";


/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

// Styles
ion-router-outlet .ion-page {
    padding-top: 57px;
    padding-bottom: 43px;
}
ion-router-outlet app-home.ion-page {
    padding-top: 0px;
    padding-bottom: 43px;
}


.vertical-divider {
    display: block;
    height: 100%;
    width: 0px;
    border-left: 1px solid #ccc;
    border-radius: 50%;
}

.horizontal-divider {
    display: block;
    height: 0px;
    width: 100%;
    border-top: 1px solid #ccc;
    border-radius: 50%;
}


.sm-hide {
    @media #{$small-screen} {
        display: none !important;
    }
}

.sm-show {
    display: none !important;

    @media #{$small-screen} {
        display: inline-block !important;
    }
}
ion-toolbar {
    --border-width: 0 !important;
}
app-footer ion-footer {
    padding: 11px;
    background: var(--ion-color-primary-tint);
}

ion-content::part(background) {
    background: transparent;
  }

  ion-content .background {
      background: radial-gradient(var(--ion-color-dark-tint), var(--ion-color-dark-shade));
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      &:after{
        content: "";
        background: url(/assets/GeeBees_bg_pattern.png);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        filter: brightness(2);
     }
}
ion-content {
    .app-title {
        position: fixed;
        left: 50px;
        top: 15px;
        font-size: 1.4em;
    }
    .background.bg-white {
        background: radial-gradient(var(--ion-color-light-tint), var(--ion-color-light));
        &:after{
            filter: brightness(5);
        }
    }
}
ion-list {
    background: transparent !important;
}
ion-item {
    background: transparent;
    --background: transparent;
    --border-color: var(--ion-color-primary);
}

.clear-btn {
  position: absolute;
  right: 0px;
  bottom: 5px;
  pointer-events: all;
  z-index: 9999;
}
ion-card-header {
    padding-top: 0;
}
ion-searchbar {
    border-radius: 15px;
    .searchbar-input-container {
        height: 100% !important;
    }
}
.cart-buttons {
    .qty {
        font-size: 2em;
        line-height: 1.8em;    
    }
}
.product-info {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    padding: 5px;
    label {
        font-size: 0.8em;
        display: block;
    }
}
ion-card-header {
    padding: 5px;
}

ion-slide {
    ion-badge {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 35px;
        height: 35px;
        padding: 8px;
        border-radius: 50%;
        span {
            vertical-align: super;
        }
    }
}
.alert-btn-success {
    color: var(--ion-color-light) !important;
    background: var(--ion-color-primary) !important;
}


@media (min-width:991px){
    ion-footer .fg {
        position: fixed;
        right: 0;
        bottom: 50px;
        width: auto;
        background: #fbf6e7;
        width: 50px;
    }
}